exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-solutions-csec-physics-csec-physics-may-june-2005-paper-2-mdx": () => import("./../../../src/pages/solutions/csec/physics/CSEC-Physics-May-June-2005-Paper-2.mdx" /* webpackChunkName: "component---src-pages-solutions-csec-physics-csec-physics-may-june-2005-paper-2-mdx" */),
  "component---src-pages-solutions-csec-physics-csec-physics-may-june-2006-paper-2-mdx": () => import("./../../../src/pages/solutions/csec/physics/CSEC-Physics-May-June-2006-Paper-2.mdx" /* webpackChunkName: "component---src-pages-solutions-csec-physics-csec-physics-may-june-2006-paper-2-mdx" */),
  "component---src-pages-solutions-csec-physics-review-mdx": () => import("./../../../src/pages/solutions/csec/physics/review.mdx" /* webpackChunkName: "component---src-pages-solutions-csec-physics-review-mdx" */),
  "component---src-pages-solutions-csec-physics-template-mdx": () => import("./../../../src/pages/solutions/csec/physics/template.mdx" /* webpackChunkName: "component---src-pages-solutions-csec-physics-template-mdx" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-tips-js": () => import("./../../../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */)
}

