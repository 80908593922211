import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import styles from "../../../../../styles/global";

const data = [
    { name: "1", x: 0, y: 0 },
    { name: "2", x: 10, y: 84 },
    { name: "3", x: 21, y: 168 },
    { name: "4", x: 32.5, y: 252 },
    { name: "5", x: 42, y: 336 },
    { name: "6", x: 50, y: 420 },
    { name: "7", x: 59, y: 504 },
]

export default function Q1Graph() {

    return (
        <div className='chart'>
            <ResponsiveContainer>
                <LineChart
                    width={300}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 5,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="x" axisLine={{ stroke: styles.axis }}/>
                    <YAxis dataKey="y" axisLine={{ stroke: styles.axis }}/>
                    <Line type="monotone" dataKey='y' stroke={styles.line} dot={{ stroke: styles.dot, strokeWidth: 2 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}