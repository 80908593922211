import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

import styles from "../../../../../styles/global";

const data = [
    { name: "1", x: 0.17, y: 0.1 },
    { name: "2", x: 0.34, y: 0.21 },
    { name: "3", x: 0.50, y: 0.31 },
    { name: "4", x: 0.64, y: 0.41 },
    { name: "5", x: 0.77, y: 0.50 },
    { name: "6", x: 0.87, y: 0.59 },
]

export default function Q1Graph() {

    return (
        <div className='chart'>
            <ResponsiveContainer>
                <LineChart
                    width={300}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 5,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="x" axisLine={{ stroke: styles.axis }}/>
                    <YAxis dataKey="y" axisLine={{ stroke: styles.axis }}/>
                    <Line type="monotone" dataKey='y' stroke={styles.line} dot={{ stroke: styles.dot, strokeWidth: 2 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}